// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dropdown */

.NBxi9hhgQqZAMqg4dB6w {
	position: relative;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	outline: none;
	cursor: pointer;
}

.ZxRZX29rnqzEwG6SLIQN {
	padding: 5px;
}

.d0LtXCwRh6jX6Rd3U7Hm {
	position: absolute;
	padding: 0;
	margin: 0;
	background-color: #FFFFFF;
	border-radius: 2px;
	list-style-type: none;
	box-shadow: 0px 0px 9px 2px rgb(0 0 0 / 10%);
	opacity: 0;
	transform: translateY(-10%);
	visibility: hidden;
	z-index: 1;
	transition: opacity 0.3s, visibility 0.3s, transform 0.3s ease;
}

.NBxi9hhgQqZAMqg4dB6w.KmqZvJfPH4XsW4tc03fw > .d0LtXCwRh6jX6Rd3U7Hm {
	top: 100%;
}

.NBxi9hhgQqZAMqg4dB6w.uO3VZ3WD4InUG91ZL3mm > .d0LtXCwRh6jX6Rd3U7Hm {
	bottom: 100%;
}

.NBxi9hhgQqZAMqg4dB6w.Y4hBnhdv_7P1Z1dSQ6NY > .d0LtXCwRh6jX6Rd3U7Hm {
	left: 0;
}

.NBxi9hhgQqZAMqg4dB6w.QuXwHLoGae6w8KHwpQld > .d0LtXCwRh6jX6Rd3U7Hm {
	right: 0;
}

.d0LtXCwRh6jX6Rd3U7Hm > li.y5npt3SrNPM1zNABBkNH {
	padding: 7px;
	font-size: 18px;
	font-weight: normal;
	color: #1D1D1B;
}

.NBxi9hhgQqZAMqg4dB6w._0bERyS0ucJL9SnH0hcsw > .d0LtXCwRh6jX6Rd3U7Hm {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

.NBxi9hhgQqZAMqg4dB6w._0bERyS0ucJL9SnH0hcsw > .ZxRZX29rnqzEwG6SLIQN::after {
	rotate: 180deg;
}

.d0LtXCwRh6jX6Rd3U7Hm > li.y5npt3SrNPM1zNABBkNH:hover, .d0LtXCwRh6jX6Rd3U7Hm > li.y5npt3SrNPM1zNABBkNH:has(a.active) {
	background-color: #BED4DB;
	border-radius: inherit;
}`, "",{"version":3,"sources":["webpack://./src/Dropdown/style.module.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;CACC,kBAAkB;CAClB,oBAAoB;CACpB,mBAAmB;CACnB,mBAAmB;CACnB,aAAa;CACb,eAAe;AAChB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,kBAAkB;CAClB,UAAU;CACV,SAAS;CACT,yBAAyB;CACzB,kBAAkB;CAClB,qBAAqB;CACrB,4CAA4C;CAC5C,UAAU;CACV,2BAA2B;CAC3B,kBAAkB;CAClB,UAAU;CACV,8DAA8D;AAC/D;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,OAAO;AACR;;AAEA;CACC,QAAQ;AACT;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,UAAU;CACV,mBAAmB;CACnB,wBAAwB;AACzB;;AAEA;CACC,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,sBAAsB;AACvB","sourcesContent":["/* Dropdown */\r\n\r\n.container {\r\n\tposition: relative;\r\n\tdisplay: inline-flex;\r\n\tflex-direction: row;\r\n\talign-items: center;\r\n\toutline: none;\r\n\tcursor: pointer;\r\n}\r\n\r\n.arrow {\r\n\tpadding: 5px;\r\n}\r\n\r\n.dropdown-list {\r\n\tposition: absolute;\r\n\tpadding: 0;\r\n\tmargin: 0;\r\n\tbackground-color: #FFFFFF;\r\n\tborder-radius: 2px;\r\n\tlist-style-type: none;\r\n\tbox-shadow: 0px 0px 9px 2px rgb(0 0 0 / 10%);\r\n\topacity: 0;\r\n\ttransform: translateY(-10%);\r\n\tvisibility: hidden;\r\n\tz-index: 1;\r\n\ttransition: opacity 0.3s, visibility 0.3s, transform 0.3s ease;\r\n}\r\n\r\n.container.down > .dropdown-list {\r\n\ttop: 100%;\r\n}\r\n\r\n.container.up > .dropdown-list {\r\n\tbottom: 100%;\r\n}\r\n\r\n.container.left > .dropdown-list {\r\n\tleft: 0;\r\n}\r\n\r\n.container.right > .dropdown-list {\r\n\tright: 0;\r\n}\r\n\r\n.dropdown-list > li.item {\r\n\tpadding: 7px;\r\n\tfont-size: 18px;\r\n\tfont-weight: normal;\r\n\tcolor: #1D1D1B;\r\n}\r\n\r\n.container.opened > .dropdown-list {\r\n\topacity: 1;\r\n\tvisibility: visible;\r\n\ttransform: translateY(0);\r\n}\r\n\r\n.container.opened > .arrow::after {\r\n\trotate: 180deg;\r\n}\r\n\r\n.dropdown-list > li.item:hover, .dropdown-list > li.item:has(:global(a.active)) {\r\n\tbackground-color: #BED4DB;\r\n\tborder-radius: inherit;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NBxi9hhgQqZAMqg4dB6w`,
	"arrow": `ZxRZX29rnqzEwG6SLIQN`,
	"dropdown-list": `d0LtXCwRh6jX6Rd3U7Hm`,
	"down": `KmqZvJfPH4XsW4tc03fw`,
	"up": `uO3VZ3WD4InUG91ZL3mm`,
	"left": `Y4hBnhdv_7P1Z1dSQ6NY`,
	"right": `QuXwHLoGae6w8KHwpQld`,
	"item": `y5npt3SrNPM1zNABBkNH`,
	"opened": `_0bERyS0ucJL9SnH0hcsw`
};
export default ___CSS_LOADER_EXPORT___;
