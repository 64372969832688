import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./style.module.css";
import Spinner from "../Spinner";
const Button = ({ children, className, classNameContainer, styleContainer, spinnerColor = 'contrast', wait = false, ...props }) => {
    const classes = ['ui-button-container'];
    classes.push(styles['container']);
    if (classNameContainer)
        classes.push(classNameContainer);
    return (_jsxs("div", { className: classes.join(' '), style: styleContainer, children: [_jsx("button", { ...props, className: 'ui-button' + (className ? ' ' + className : ''), children: children }), _jsx("div", { className: 'ui-button-spinner ' + styles['wait-indicator'] + (wait ? ' ' + styles['active'] : ''), children: _jsx(Spinner, { size: "small", color: spinnerColor }) })] }));
};
export default Button;
