import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './style.module.css';
import Spinner from '../Spinner';
import Progress from '../Progress';
const Form = ({ children, wait, progress, progressRadius = true, className, spinnerColor, onSubmit, ...props }) => {
    const submit = (e) => {
        e.preventDefault();
        if (typeof onSubmit === 'function') {
            onSubmit(e);
        }
    };
    const classes = [];
    classes.push(styles['container']);
    if (wait)
        classes.push(styles['wait']);
    if (className)
        classes.push(className);
    return (_jsxs("form", { ...props, onSubmit: submit, className: classes.join(' '), children: [children, _jsx("div", { className: styles['wait-indicator'], children: typeof progress === 'number' ? _jsx(Progress, { radius: progressRadius, value: progress }) : _jsx(Spinner, { color: spinnerColor }) })] }));
};
export default Form;
