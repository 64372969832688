import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect, createContext } from "react";
import styles from "./style.module.css";
import Arrow from "../Components/Arrow";
export const Context = createContext({
    close: () => { }
});
;
const Dropdown = ({ children, className, items, direction = 'down', position = 'right', split = false, disabled, hover = false, ...props }) => {
    const containerRef = useRef(null);
    const hoverTimeout = useRef();
    const [isOpen, setOpen] = useState(false);
    const close = () => {
        setOpen(false);
    };
    const toggle = () => {
        setOpen((isOpen) => {
            if (disabled)
                return false;
            return !isOpen;
        });
    };
    const handleClickArrow = () => {
        toggle();
    };
    const handleClick = () => {
        if (!split) {
            toggle();
        }
        if (hover) {
            close();
        }
    };
    const handleBlur = (e) => {
        if (e.currentTarget.contains(e.relatedTarget))
            return;
        close();
    };
    const handleMouseEnter = () => {
        if (!hover || isOpen)
            return;
        hoverTimeout.current = setTimeout(toggle, 50);
    };
    const handleMouseOut = () => {
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
    };
    useEffect(() => {
        const element = containerRef.current;
        const classList = element?.classList;
        if (isOpen) {
            classList?.add(styles['opened']);
            element?.focus();
        }
        else {
            classList?.remove(styles['opened']);
        }
    }, [isOpen]);
    const classes = ['ui-dropdown'];
    classes.push(styles['container']);
    if (className)
        classes.push(className);
    if (direction)
        classes.push(styles[direction]);
    if (position)
        classes.push(styles[position]);
    return (_jsx(Context.Provider, { value: {
            close
        }, children: _jsxs("div", { ...props, className: classes.join(' '), ref: containerRef, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseOut, tabIndex: 1, onBlur: handleBlur, children: [(position === 'left' && !disabled) && _jsx(Arrow, { className: styles['arrow'], onClick: handleClickArrow }), _jsx("div", { onClick: handleClick, children: children }), (position === 'right' && !disabled) && _jsx(Arrow, { className: styles['arrow'], onClick: handleClickArrow }), (items && !disabled) && _jsx("ul", { className: styles['dropdown-list'], children: items })] }) }));
};
export default Dropdown;
