import { useState } from "react";
import {
	useNavigate
} from 'react-router-dom';

import {
	Form,
	Input,
	Button,
	useForm
} from "react-artasys-ui";

import API from "../API";

const Register = () => {
	const navigate = useNavigate();
	const [wait, setWait] = useState(false);
	const [params, setParams] = useState({
		name: '',
		surname: '',
		patronymic: '',
		email: '',
		phone: '',
		password: ''
	});
	const [rePassword, setRePassword] = useState('');

	const {
		register,
		handleSubmit,
		setErrors,
		GlobalError
	} = useForm();

	const formSubmit = () => {
		setWait(true);
		API.request({
			path: 'register',
			data: params,
			success: (e) => {
				if (e.success) {
					navigate('/activate',{
						state: {
							type: 'register',
							user: params.email
						}
					});
				}
			},
			error: (e) => {
				setErrors(e);
			},
			complete: () => {
				setWait(false);
			}
		});
	};

	return(<Form onSubmit={handleSubmit(formSubmit)} wait={wait}>
		<Input type="text" placeholder="Имя" value={params.name} {...register('name')} onChangeText={name => setParams((state) => ({...state, name}))} required autoFocus/>
		<Input type="text" placeholder="Фамилия" value={params.surname} {...register('surname')} onChangeText={surname => setParams((state) => ({...state, surname}))}/>
		<Input type="text" placeholder="Отчество" value={params.patronymic} {...register('patronymic')} onChangeText={patronymic => setParams((state) => ({...state, patronymic}))}/>
		<Input type="email" placeholder="Email" value={params.email} {...register('email')} onChangeText={email => setParams((state) => ({...state, email}))} required/>
		<Input type="tel" placeholder="Телефон" value={params.phone} {...register('phone')} onChangeText={phone => setParams((state) => ({...state, phone}))} required/>
		<Input type="password" placeholder="Пароль" value={params.password} {...register('password')} onChangeText={password => setParams((state) => ({...state, password}))} required/>
		<Input type="password" placeholder="Пароль ещё раз" {...register('re-password',{validate: value => value === params.password || 'Пароли не совпадают'})} value={rePassword} onChangeText={setRePassword} required/>
		<GlobalError/>
		<Button type="submit">Регистрация</Button>
	</Form>);
};

export default Register;