import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import Spinner from "../Spinner";
import styles from "./style.module.css";
;
const Image = ({ onChange, onClick, onId, onRemove, ...props }) => {
    const { base64, src } = props;
    const [wait, setWait] = useState(true);
    const confirmRemove = useRef(false);
    const currentId = useRef();
    const setId = (id) => {
        currentId.current = id;
        if (typeof onId !== 'function')
            return;
        onId(id);
    };
    const handleClick = () => {
        if (typeof onClick === 'function') {
            onClick({
                ...props,
                setWait,
                setId,
                id: currentId.current
            });
        }
    };
    const handleLoad = () => {
        setWait(false);
    };
    const handleRemove = (e) => {
        if (!e.target)
            return;
        const element = e.target;
        const remove = () => {
            if (!confirmRemove.current)
                return;
            confirmRemove.current = false;
            element.blur();
            if (typeof onRemove === 'function') {
                onRemove();
            }
        };
        remove();
        confirmRemove.current = true;
    };
    const handleCancelRemove = () => {
        confirmRemove.current = false;
    };
    useEffect(() => {
        if (typeof onChange !== 'function' || src)
            return;
        onChange({
            ...props,
            setWait,
            setId,
            id: currentId.current
        });
    }, []);
    return (_jsxs("span", { className: styles['image'], onClick: handleClick, children: [_jsx("span", { className: styles['close'], onClick: handleRemove, onBlur: handleCancelRemove, tabIndex: 1 }), _jsx("img", { src: src ?? base64?.toString(), onLoad: handleLoad }), wait && _jsx("span", { className: styles['wait'], children: _jsx(Spinner, {}) })] }));
};
export default Image;
