import { useEffect, useRef, useState } from 'react';
import {
	Routes,
	Route
} from 'react-router-dom';
import styles from './style.module.css';

import { TRequest, TUser } from './types';
import Welcome from './Pages/Welcome';
import Layout from './Layout';
import Login from './Pages/Login';
import Register from './Pages/Register';
import PasswordReset from './Pages/PasswordReset';
import Activate from './Pages/Activate';
import NewPassword from './Pages/NewPassword';
import Bridge from './Bridge';
import packageJSON from '../../package.json';


export type IApp = {
	request: {
		path: string;
		route: (route: string) => void;
		request: TRequest;
	};
}

const App = () => {
	const rootRef = useRef<HTMLDivElement>(null);
	const [user, setUser] = useState<TUser>();

	useEffect(() => {
		if (rootRef.current) {
			new ResizeObserver(() => {
				const width = rootRef.current?.scrollWidth ?? 0;
				const height = rootRef.current?.scrollHeight ?? 0;
				Bridge.setSize({
					width,
					height
				});
			}).observe(rootRef.current);
		}

		Bridge.addListener('user', setUser);
		Bridge.init();
	},[]);

	if (!Bridge.getOrigin() || !Bridge.getRoute()) return null;

	return(<div className={styles['root']} ref={rootRef}>
		<Routes>
			<Route path='welcome' element={<Welcome user={user}/>}/>
			{
				!user?.id &&
					<Route element={<Layout/>}>
						<Route path='login' element={<Login/>}/>
						<Route path='register' element={<Register/>}/>
						<Route path='password-reset' element={<PasswordReset/>}/>
						<Route path='activate' element={<Activate/>}/>
						<Route path='new-password' element={<NewPassword/>}/>
					</Route>
			}
		</Routes>
		<div className={styles['footer']}>
			<span className={styles['version']}>v{packageJSON.version}</span>
			<div className={styles['logo']}/>
		</div>
	</div>)
};

export default App;