import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./style.module.css";
;
const Arrow = ({ className, direction = 'down', ...props }) => {
    const classes = ['ui-component-arrow'];
    classes.push(styles['container']);
    if (direction)
        classes.push(styles[direction]);
    if (className)
        classes.push(className);
    return (_jsx("div", { ...props, className: classes.join(' ') }));
};
export default Arrow;
