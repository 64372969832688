import { useState } from "react";
import {
	useNavigate
} from 'react-router-dom';

import {
	Form,
	Input,
	Button,
	useForm
} from "react-artasys-ui";
import API from "../API";

const PasswordReset = () => {
	const navigate = useNavigate();
	const [wait, setWait] = useState(false);
	const [email, setEmail] = useState('');

	const {
		register,
		setErrors,
		handleSubmit,
		GlobalError
	} = useForm();

	const formSubmit = () => {
		setWait(true);
		API.request({
			path: 'password_reset',
			data: {
				email
			},
			success: (e) => {
				if (e.success) {
					navigate('/activate',{
						state: {
							type: 'password_reset',
							user: email
						}
					});
				}
			},
			error: (e) => {
				setErrors(e);
			},
			complete: () => {
				setWait(false);
			}
		});
		
	};

	return(<Form onSubmit={handleSubmit(formSubmit)} wait={wait}>
			<Input type="text" placeholder="Email/Логин" {...register('email')} value={email} onChangeText={setEmail} required/>
			<GlobalError/>
			<Button type="submit">Отправить</Button>
		</Form>);
};

export default PasswordReset;