// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Form */

.xfbzHBioSpvllNE73Mnu {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
}

.xfbzHBioSpvllNE73Mnu > .G02CNkV3P8cBCiMWQBGf {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #FFFFFF9F;
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
	z-index: -1;
	opacity: 0;
	transition: opacity 0.3s;
}

.xfbzHBioSpvllNE73Mnu.W2hPp2Lh7DUhgii9zuA0 > .G02CNkV3P8cBCiMWQBGf {
	z-index: 1;
	opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/Form/style.module.css"],"names":[],"mappings":"AAAA,SAAS;;AAET;CACC,OAAO;CACP,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;CACZ,2BAA2B;CAC3B,kCAA0B;SAA1B,0BAA0B;CAC1B,WAAW;CACX,UAAU;CACV,wBAAwB;AACzB;;AAEA;CACC,UAAU;CACV,UAAU;AACX","sourcesContent":["/* Form */\r\n\r\n.container {\r\n\tflex: 1;\r\n\tdisplay: flex;\r\n\tflex-direction: column;\r\n\talign-items: center;\r\n\tgap: 10px;\r\n}\r\n\r\n.container > .wait-indicator {\r\n\tdisplay: flex;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tposition: absolute;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tbackground-color: #FFFFFF9F;\r\n\tbackdrop-filter: blur(2px);\r\n\tz-index: -1;\r\n\topacity: 0;\r\n\ttransition: opacity 0.3s;\r\n}\r\n\r\n.container.wait > .wait-indicator {\r\n\tz-index: 1;\r\n\topacity: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `xfbzHBioSpvllNE73Mnu`,
	"wait-indicator": `G02CNkV3P8cBCiMWQBGf`,
	"wait": `W2hPp2Lh7DUhgii9zuA0`
};
export default ___CSS_LOADER_EXPORT___;
