import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { default as DropdownUI } from "./Dropdown";
import Item from "./Item";
;
const Dropdown = {
    ...forwardRef(({ ...args }, ref) => _jsx(DropdownUI, { ...args })),
    Item
};
export { Item as DropdownItem };
export default Dropdown;
