(function webpackUniversalModuleDefinition(root, factory) {
	//Easy Event Emitter
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	//Easy Event Emitter
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	//Easy Event Emitter
	else if(typeof exports === 'object')
		exports["EventEmitter"] = factory();
	//Easy Event Emitter
	else
		root["EventEmitter"] = factory();
})(self, () => {
return 