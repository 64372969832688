import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, createContext, useState, useEffect, useRef, useImperativeHandle } from "react";
import Element from "../Form/Element";
import Arrow from "../Components/Arrow";
import styles from "./style.module.css";
export const Context = createContext({
    selected: '',
    emptyValue: { current: {} },
    setSelect: (value) => { },
    setSelected: (value) => { },
    setTitle: (title) => { },
});
;
const Select = forwardRef(({ children, onChangeSelect, value, formValue, ...props }, ref) => {
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const emptyValue = useRef(false);
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState('');
    const [title, setTitle] = useState();
    const open = () => {
        if (props.disabled)
            return;
        setOpen(true);
    };
    const close = () => {
        setOpen(false);
    };
    const handleClick = () => {
        isOpen ? close() : open();
    };
    const triggerNativeEvent = (value) => {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value")?.set;
        nativeInputValueSetter?.call(inputRef.current, value);
        const event = new Event('input', { bubbles: true });
        inputRef.current.dispatchEvent(event);
    };
    const setSelect = (val) => {
        if (typeof onChangeSelect === 'function') {
            onChangeSelect(val);
        }
        if (typeof value === 'undefined') {
            setSelected(val);
        }
        close();
    };
    const handleInput = (e) => {
        if (props.disabled)
            return;
        if (typeof props.onInput === 'function') {
            props.onInput(e);
        }
        if (typeof props.onChange === 'function') {
            props.onChange(e);
        }
        setSelected(e.target.value);
    };
    useEffect(() => {
        if (!selected)
            return;
        triggerNativeEvent(selected);
    }, [selected]);
    useEffect(() => {
        setSelected(String(value ?? formValue));
    }, [value, formValue]);
    useEffect(() => {
        const element = containerRef.current;
        const classList = element.classList;
        if (isOpen) {
            classList.remove(styles['hidden']);
            requestAnimationFrame(() => {
                classList.add(styles['opened']);
                element?.focus();
            });
        }
        else {
            if (classList.contains(styles['opened'])) {
                element.ontransitionend = () => {
                    classList.add(styles['hidden']);
                    element.ontransitionend = null;
                };
            }
            classList.remove(styles['opened']);
        }
    }, [isOpen]);
    useImperativeHandle(ref, () => inputRef.current, []);
    const classes = [''];
    classes.push(styles['container'], styles['hidden']);
    return (_jsx(Element, { ...props, classNameContainer: styles['container-element'], children: (props) => _jsxs(Context.Provider, { value: {
                selected,
                emptyValue: emptyValue,
                setSelect,
                setSelected,
                setTitle
            }, children: [_jsx("input", { ...props, type: "hidden", value: selected, onInput: handleInput, ref: inputRef }), _jsxs("div", { className: classes.join(' '), ref: containerRef, tabIndex: 1, onBlur: close, children: [_jsxs("div", { className: styles['select'], onClick: handleClick, children: [_jsx("span", { className: styles['title'], children: title }), _jsx(Arrow, { className: styles['arrow'] })] }), _jsx("ul", { className: styles['select-list'], children: children })] })] }) }));
});
export default Select;
