import {
	Outlet,
	useLocation,
	Link,
	useNavigate
} from 'react-router-dom';
import styles from "./style.module.css";
import { useEffect, useRef } from 'react';
import Bridge from './Bridge';

const titlesAssoc = {
	'login': 'Авторизация',
	'register': 'Регистрация',
	'password-reset': 'Восстановление пароля',
	'activate': 'Активация',
	'new-password': 'Новый пароль'
} as {
	[index: string]: string;
};

const Layout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const path = location.pathname.replace('/', '');
		Bridge.setTitle(titlesAssoc[path] ?? '...');
	},[location.pathname]);

	useEffect(() => {
		const listener = Bridge.addListener('navigate', (path) => {
			navigate(path);
		});

		return () => {
			listener.remove();
		}
	},[]);

	return(<div className={styles['container']}>
		<Outlet/>
		<div className={styles['navigation']}>
			{location.pathname !== "/login" && <Link to="/login">{titlesAssoc['login']}</Link>}
			{location.pathname !== "/password-reset" && <Link to="/password-reset">{titlesAssoc['password-reset']}</Link>}
			{location.pathname !== "/register" && <Link to="/register">{titlesAssoc['register']}</Link>}
		</div>
	</div>);
};

export default Layout;