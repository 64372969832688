import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
;
const File = ({ onChange, children, multiple, accept, ...props }) => {
    const selector = useRef();
    const handleChange = (e) => {
        const target = e.target;
        if (target.files) {
            for (const file of target.files) {
                const reader = new FileReader();
                reader.onload = (evt) => {
                    const data = evt.target?.result?.toString().split(';base64,');
                    const mime = (data?.[0].replace('data:', '') ?? '');
                    const contentData = data?.[1] ?? '';
                    if (typeof onChange === 'function') {
                        selector.current.value = '';
                        onChange({
                            name: file.name,
                            type: file.type,
                            size: file.size,
                            mime: mime,
                            data: contentData,
                            base64: evt.target?.result
                        });
                    }
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const handleDialog = (e) => {
        if (props.disabled)
            return;
        selector.current.click();
        e.preventDefault();
    };
    const mount = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.onchange = handleChange;
        if (accept && accept.length > 0) {
            fileSelector.setAttribute('accept', accept.join(','));
        }
        if (multiple) {
            fileSelector.setAttribute('multiple', 'multiple');
        }
        return fileSelector;
    };
    useEffect(() => {
        selector.current = mount();
        return () => {
            selector.current?.remove();
        };
    }, []);
    return (_jsx("div", { ...props, onClick: handleDialog, children: children }));
};
export default File;
