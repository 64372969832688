// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Button */

.ZE3z3TekxrlJSxVDUmEf {
	position: relative;
	border: 1px solid #5EBED6;
	background-color: #BED4DB;
}

.ZE3z3TekxrlJSxVDUmEf button {
	display: block;
	width: 100%;
    height: 100%;
	border: 0;
    padding: 5px 8px;
    font-size: 1.1em;
	color: #1D1D1B;
	cursor: pointer;
	border-radius: 0.1em;
	transition: background-color 0.3s;
}

.ZE3z3TekxrlJSxVDUmEf button:hover {
	background-color: #ADCED8;
	color: #1D1D1B;
}

.ZE3z3TekxrlJSxVDUmEf > .pjKagDFEw9y41wTr0wJE {
	position: absolute;
	display: flex;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: inherit;
	z-index: -1;
	opacity: 0;
	transition: opacity 0.3s;
}

.ZE3z3TekxrlJSxVDUmEf > .pjKagDFEw9y41wTr0wJE.enIw5hc1054LdO67uSov {
	opacity: 1;
	z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/Button/style.module.css"],"names":[],"mappings":"AAAA,WAAW;;AAEX;CACC,kBAAkB;CAClB,yBAAyB;CACzB,yBAAyB;AAC1B;;AAEA;CACC,cAAc;CACd,WAAW;IACR,YAAY;CACf,SAAS;IACN,gBAAgB;IAChB,gBAAgB;CACnB,cAAc;CACd,eAAe;CACf,oBAAoB;CACpB,iCAAiC;AAClC;;AAEA;CACC,yBAAyB;CACzB,cAAc;AACf;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;CACZ,uBAAuB;CACvB,mBAAmB;CACnB,yBAAyB;CACzB,WAAW;CACX,UAAU;CACV,wBAAwB;AACzB;;AAEA;CACC,UAAU;CACV,UAAU;AACX","sourcesContent":["/* Button */\r\n\r\n.container {\r\n\tposition: relative;\r\n\tborder: 1px solid #5EBED6;\r\n\tbackground-color: #BED4DB;\r\n}\r\n\r\n.container button {\r\n\tdisplay: block;\r\n\twidth: 100%;\r\n    height: 100%;\r\n\tborder: 0;\r\n    padding: 5px 8px;\r\n    font-size: 1.1em;\r\n\tcolor: #1D1D1B;\r\n\tcursor: pointer;\r\n\tborder-radius: 0.1em;\r\n\ttransition: background-color 0.3s;\r\n}\r\n\r\n.container button:hover {\r\n\tbackground-color: #ADCED8;\r\n\tcolor: #1D1D1B;\r\n}\r\n\r\n.container > .wait-indicator {\r\n\tposition: absolute;\r\n\tdisplay: flex;\r\n\ttop: 0;\r\n\tleft: 0;\r\n\twidth: 100%;\r\n\theight: 100%;\r\n\tjustify-content: center;\r\n\talign-items: center;\r\n\tbackground-color: inherit;\r\n\tz-index: -1;\r\n\topacity: 0;\r\n\ttransition: opacity 0.3s;\r\n}\r\n\r\n.container > .wait-indicator.active {\r\n\topacity: 1;\r\n\tz-index: 1;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZE3z3TekxrlJSxVDUmEf`,
	"wait-indicator": `pjKagDFEw9y41wTr0wJE`,
	"active": `enIw5hc1054LdO67uSov`
};
export default ___CSS_LOADER_EXPORT___;
