
import styles from "../style.module.css";
import { TUser } from "../types";

import {
	Form,
	Button
} from "react-artasys-ui";
import Bridge from "../Bridge";

type TWelcome = {
	user?: TUser;
}

const Welcome = ({user}: TWelcome) => {
	const hide = () => {
		Bridge.close();
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		hide();
	};

	return(<Form className={styles['welcome']} onSubmit={handleSubmit}>
		<span className={styles['text']}>{user?.name}, добро пожаловать!</span>
		<Button onClick={hide}>Закрыть</Button>
	</Form>);
};

export default Welcome;