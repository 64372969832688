import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useState, useEffect } from "react";
import Element from "../Form/Element";
const Input = forwardRef(({ onChange, onInput, onChangeText, formValue, ...props }, ref) => {
    const [currentValue, setCurrentValue] = useState('');
    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
        if (typeof onChangeText === 'function') {
            onChangeText(e.target.value);
        }
    };
    const handleInput = (e) => {
        if (props.disabled)
            return;
        if (typeof onInput === 'function') {
            onInput(e);
        }
        setCurrentValue(e.target.value);
    };
    useEffect(() => {
        const value = props.value ?? formValue;
        setCurrentValue(value ? String(value) : '');
    }, [props.value, formValue]);
    return (_jsx(Element, { ...props, children: (props) => _jsx("input", { ...props, placeholder: "", onChange: handleChange, value: currentValue, onInput: handleInput, ref: ref }) }));
});
export default Input;
