//@ts-ignore
import md5 from 'md5';
import { TRequestParams } from "./types";
import Bridge from '../Bridge';

class API {
	private static instance = new API;
	private host = 'https://id.artasky.net/api/';
	private originHost = '';
	private hashOriginHost = '';

	constructor() {
		this.request = this.request.bind(this);
		this.originHost = Bridge.getOrigin();
		const url = new URL(Bridge.getOrigin());
		this.hashOriginHost = md5(url.host);
	}

	public request(params: TRequestParams) {
		try {
			fetch((params.toParent ? this.originHost + '/' : this.host) + params.path,{
				method: 'post',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Ref-Hash': this.hashOriginHost
				},
				body: JSON.stringify(params.data)
			})
			.then(async (data) => {
				const code = data.status;
				
				const text = await data.text();
				try{
					const obj = JSON.parse(text);
					if (code >= 200 && code <= 299) {
						params.success?.(obj);
					}else{
						params.error?.(obj);
					}
				} catch(e) {
					params.error?.({message: 'Error core'});
				}
			})
			.catch(params.error)
			.finally(params.complete);
		}catch(e){}
	}

	public static request = this.instance.request;
}

export default API;