import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import File from "../File";
import styles from "./style.module.css";
import Image from "./Image";
;
const UploadImages = ({ imagesArray, onChange, onChangeArray, onClick }) => {
    const [images, setImages] = useState([]);
    const handleChange = (image) => {
        setImages((images) => [...images, { ...image }]);
    };
    const handleChangeId = (image) => {
        return (id) => {
            setImages((images) => images.map((currentImage) => {
                if (image === currentImage) {
                    currentImage.id = id;
                }
                return currentImage;
            }));
        };
    };
    const handleRemove = (image) => {
        return () => setImages((images) => images.filter((currentImage) => currentImage !== image));
    };
    useEffect(() => {
        if (typeof onChangeArray !== 'function')
            return;
        onChangeArray(images);
    }, [images]);
    useEffect(() => {
        if (!imagesArray)
            return;
        setImages(imagesArray);
    }, [imagesArray]);
    return (_jsxs("div", { className: styles['container'], children: [images.map((image, index) => _jsx(Image, { ...image, onChange: onChange, onClick: onClick, onRemove: handleRemove(image), onId: handleChangeId(image) }, (image.name + index))), _jsx(File, { onChange: handleChange, accept: ['image/png'], className: styles['new'], multiple: true })] }));
};
export default UploadImages;
