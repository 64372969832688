import EventEmitter,{
	type Event
} from "easy-event-emitter";
import type { TUser } from "./types";
import type { TError } from "./API/types";

type TBridgeData = {
	'route': string;
	'success': TUser;
	'error': TError;
	'title': string;
	'client-hash': string;
	'navigate': string;
	'wait': boolean;
	'init': boolean;
	'user': TUser;
	'close': boolean;
	'size': {
		width: number;
		height: number;
	}
};

type TBridgeMessage = {
	type: keyof TBridgeData;
	data: TBridgeData[TBridgeMessage['type']];
};

const Bridge = new (class Bridge {
	private route = 'login';
	private origin = '';
	private events = new EventEmitter;

	constructor() {
		const searchParams = new URLSearchParams(window.location.search);
		this.origin = searchParams.get('origin') ?? '';
		this.route = searchParams.get('start') ?? 'login';
	}

	public init() {
		
		window.onmessage = (e) => {
			if (e.data?.event === '_artasys-auth') {
				const type = e.data?.type ?? '';
				const data = e.data?.data ?? {};

				this.events.emit(type, data);
			}
		};
		this.message({
			type: 'init',
			data: true
		});
	}

	private message({type, data}: TBridgeMessage) {
		window.parent?.postMessage({
			event: '_artasys-auth',
			type,
			data
		}, '*');
	}

	public addListener<ID extends keyof TBridgeData, DATA extends TBridgeData[ID]>(event: ID, callback: (data: DATA) => void): Event {
		return this.events.addListener(event, callback);
	}

	public emit<ID extends keyof TBridgeData, DATA extends TBridgeData[ID]>(event: ID, data: DATA) {
		this.message({
			type: event,
			data
		});
	}

	public getOrigin() {
		return this.origin;
	}

	public setRoute(route: string) {
		this.route = route;
		this.message({
			type: 'route',
			data: route
		});
	}

	public getRoute() {
		return this.route;
	}

	public setTitle(title: string) {
		this.message({
			type: 'title',
			data: title
		});
	}

	public setSize(size: TBridgeData['size']) {
		this.message({
			type: 'size',
			data: size
		});
	}

	public setError(error: TBridgeData['error']) {
		this.message({
			type: 'error',
			data: error
		});
	}

	public setSuccess(success: TBridgeData['success']) {
		this.message({
			type: 'success',
			data: success
		});
	}

	public close() {
		this.message({
			type: 'close',
			data: true
		});
	}
});

export default Bridge;