import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styles from "./style.module.css";
import { useForm as useHookForm } from "react-hook-form";
const useForm = () => {
    const [globalError, setGlobalError] = useState();
    const form = useHookForm({
        reValidateMode: 'onChange'
    });
    const register = (name, options) => {
        const inputRegister = form.register(name, options);
        const onChange = (e) => {
            setGlobalError(null);
            form.clearErrors(name);
            return inputRegister.onChange(e);
        };
        const formValue = form.watch(name);
        const errors = form?.formState.errors;
        return {
            ...inputRegister,
            onChange,
            formValue,
            error: errors?.[name]?.message?.toString()
        };
    };
    const setErrors = (e) => {
        if (e.errors) {
            Object.keys(e.errors).forEach((field) => form.setError(field, { message: e.errors?.[field]?.[0] }));
        }
        else {
            setGlobalError(e);
        }
    };
    const GlobalError = () => (_jsx(_Fragment, { children: (globalError && !globalError.errors) && _jsx("div", { className: styles['global-error'], children: globalError.message }) }));
    return {
        ...form,
        register,
        GlobalError,
        setErrors
    };
};
export const validate = {
    email: {
        pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Неверный адрес электронной почты",
        }
    }
};
export default useForm;
