import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useRef, useEffect, useState, useImperativeHandle } from "react";
import Element from "../Form/Element";
const TextArea = forwardRef(({ onChange, onInput, onChangeText, formValue, ...props }, ref) => {
    const textareaRef = useRef(null);
    const [currentValue, setCurrentValue] = useState(props.value);
    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
        if (typeof onChangeText === 'function') {
            onChangeText(e.target.value);
        }
    };
    const handleInput = (e) => {
        if (typeof onInput === 'function') {
            onInput(e);
        }
        setCurrentValue(e.target.value);
    };
    useEffect(() => {
        if (!textareaRef.current)
            return;
        textareaRef.current.style.height = '0';
        const height = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = height + 'px';
    }, [currentValue]);
    useEffect(() => {
        setCurrentValue(props.value ?? formValue);
    }, [props.value, formValue]);
    useImperativeHandle(ref, () => textareaRef.current);
    return (_jsx(Element, { ...props, children: (props) => _jsx("textarea", { ...props, placeholder: "", value: currentValue, onChange: handleChange, onInput: handleInput, ref: textareaRef }) }));
});
export default TextArea;
