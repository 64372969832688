import { useEffect, useState } from "react";
import {
	useNavigate,
	useLocation
} from 'react-router-dom';

import {
	Form,
	useForm,
	Input,
	Button
} from "react-artasys-ui";
import { TRequest, TUser } from "../types";

import API from "../API";
import Bridge from '../Bridge';

const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [wait, setWait] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const {
		register,
		handleSubmit,
		GlobalError,
		setErrors
	} = useForm();
	
	const formSubmit = () => {
		setWait(true);
		API.request({
			path: 'login',
			data: {
				email,
				password
			},
			success: (e) => {
				if (!e) return Bridge.setError({message: ''});
				/**
				 * Перенаправляем если пользователь не активирован
				 */
				if (e.activate) {
					navigate('/activate',{
						state: {
							type: 'register',
							user: e.user
						}
					});
					return;
				}
				/**
				 * Делаем запрос на бэк сервиса для подтверждения пользователя
				 */
				Bridge.emit('client-hash', e.hash);
			},
			error: (e) => {
				setWait(false);
				setErrors(e);
				Bridge.setError(e);
			}
		});
	};

	useEffect(() => {
		setEmail(location.state?.user);
	},[location.state?.user]);

	useEffect(() => {
		const listener = Bridge.addListener('wait', setWait);
		return () => {
			listener.remove();
		}
	},[]);

	return(
		<Form onSubmit={handleSubmit(formSubmit)} wait={wait}>
			<Input type="text" placeholder="Email/Логин" {...register('email')} value={email} disabled={location.state?.user ? true : false} onChangeText={setEmail} autoComplete="email" autoFocus required/>
			<Input type="password" placeholder="Пароль" {...register('password')} value={password} onChangeText={setPassword} required/>
			<GlobalError/>
			<Button type="submit" wait={wait}>Войти</Button>
		</Form>
	);
};

export default Login;