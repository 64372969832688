import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, cloneElement } from "react";
import styles from "./style.module.css";
const Element = ({ children, beforeElement, afterElement, error, placeholder, styleContainer, classNameContainer, hiddenContainer, formValue, ...props }) => {
    const [currentError, setCurrentError] = useState('');
    useEffect(() => {
        setCurrentError(error ?? '');
    }, [error]);
    const classes = ['ui-form-element-container'];
    classes.push(styles['container']);
    if (currentError)
        classes.push(styles['error']);
    if (props.disabled)
        classes.push(styles['disabled']);
    if (hiddenContainer)
        classes.push(styles['hidden']);
    if (classNameContainer)
        classes.push(classNameContainer);
    return (_jsxs(_Fragment, { children: [_jsxs("label", { className: classes.join(' '), style: styleContainer, children: [beforeElement ? cloneElement(beforeElement, { key: 'ui-before-element' }) : null, _jsxs("div", { className: 'ui-form-element ' + styles['element'], children: [typeof children === 'function' ? children(props) : null, placeholder && _jsx("span", { className: 'ui-form-element-placeholder ' + styles['placeholder'], children: placeholder })] }), afterElement ? cloneElement(afterElement, { key: 'ui-after-element' }) : null] }), currentError && _jsx("div", { className: 'ui-form-error ' + styles['error'], children: currentError })] }));
};
export default Element;
