import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect } from "react";
import { Context } from "./Select";
import styles from "./style.module.css";
;
const Option = ({ children, value, disabled, hidden, onClick, ...props }) => {
    const context = useContext(Context);
    const handleClick = (e) => {
        if (typeof value === 'undefined' || disabled)
            return;
        context.setSelect(value);
        if (typeof onClick === 'function') {
            onClick(e);
        }
    };
    useEffect(() => {
        if (children && (value === context.selected || !context.emptyValue.current)) {
            context.setTitle(children);
            if (!context.emptyValue.current && value) {
                context.setSelected(value);
            }
            context.emptyValue.current = true;
        }
    }, [context.selected]);
    if (hidden)
        return (null);
    const classes = ['ui-select-option'];
    classes.push(styles['option']);
    if (context.selected === value)
        classes.push(styles['active'], 'active');
    if (disabled)
        classes.push(styles['disabled'], 'disabled');
    return (_jsx("li", { ...props, onClick: handleClick, className: classes.join(' '), children: children }));
};
export default Option;
