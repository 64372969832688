import { useState } from "react";
import {
	useNavigate,
	useLocation
} from 'react-router-dom';

import {
	Form,
	Input,
	Button,
	useForm
} from "react-artasys-ui";
import API from "../API";

const NewPassword = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [wait, setWait] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const {
		register,
		handleSubmit,
		setErrors,
		GlobalError
	} = useForm();

	const formSubmit = () => {
		setWait(true);
		API.request({
			path: 'new_password',
			data: {
				password,
				hash: location.state?.hash
			},
			success: (e) => {
				if (e.success) {
					navigate('/login',{
						state: {
							user: e.user
						}
					})
				}
			},
			error: (e) => {
				setErrors(e);
			},
			complete: () => {
				setWait(false);
			}
		});
	};

	return(
	<Form onSubmit={handleSubmit(formSubmit)} wait={wait}>
		<Input type="text" value={location.state?.user} disabled/>
		<Input type="password" placeholder="Пароль" {...register('password')} value={password} onChangeText={setPassword} required/>
		<Input type="password" placeholder="Пароль ещё раз" {...register('re-password',{validate: value => value === password || 'Пароли не совпадают'})} value={confirmPassword} onChangeText={setConfirmPassword} required/>
		<GlobalError/>
		<Button type="submit">Отправить</Button>
	</Form>
	);
};

export default NewPassword;