import { jsx as _jsx } from "react/jsx-runtime";
import styles from "./style.module.css";
const Spinner = ({ size, color, ...props }) => {
    const classes = ['ui-spinner'];
    classes.push(styles['spinner']);
    if (size)
        classes.push(styles[size], 'ui-spinner-' + size);
    if (color)
        classes.push(styles[color], 'ui-spinner-' + color);
    if (props.className)
        classes.push(props.className);
    return (_jsx("div", { ...props, className: classes.join(' ') }));
};
export default Spinner;
