import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from "react";
import styles from "./style.module.css";
import { Context } from "./Dropdown";
;
const Item = ({ children, onClick, autoClose = true, active, className, ...props }) => {
    const context = useContext(Context);
    const handleClick = (e) => {
        if (typeof onClick === 'function') {
            onClick(e);
        }
        if (autoClose) {
            context.close();
        }
    };
    const classes = ['ui-dropdown-item'];
    classes.push(styles['item']);
    if (active)
        classes.push(styles['active'], 'active');
    if (className)
        classes.push(className);
    return (_jsx("li", { ...props, onClick: handleClick, className: classes.join(' '), children: typeof children === 'function' ? children(context) : children }));
};
export default Item;
