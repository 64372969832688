import { FormEvent, useEffect, useState } from 'react';
import {
	useNavigate,
	useLocation
} from 'react-router-dom';

import {
	Form,
	Button,
	Input,
	useForm
} from 'react-artasys-ui';
import styles from '../style.module.css';

import API from '../API';

const Activate = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [wait, setWait] = useState(false);
	const [code, setCode] = useState('');

	const {
		register,
		handleSubmit,
		setErrors,
		GlobalError
	} = useForm();

	const formSubmit = () => {
		setWait(true);
		API.request({
			path: 'activate',
			data: {
				code,
				email: location.state?.user
			},
			success: (e) => {
				if (e.success) {
					switch(location.state?.type) {
						case 'register':
							navigate('/login', {
								state: {
									user: e.user
								}
							});
						break;
						case 'password_reset':
							navigate('/new-password', {
								state: {
									hash: e.hash,
									user: e.user
								}
							});
						break;
					}
				}
			},
			error: (e) => {
				setErrors(e);
			},
			complete: () => {
				setWait(false);
			}
		});
	};

	return(<Form onSubmit={handleSubmit(formSubmit)} wait={wait}>
		<span className={styles['activate']}>На ваш почтовый ящик было отправлено письмо с кодом подтверждения, пожалуйста введите его в поле ниже</span>
		<Input placeholder='Код подтверждения' value={code} {...register('code',{min: 4})} onChangeText={setCode} autoComplete='off' autoFocus/>
		<GlobalError/>
		<Button>Подтвердить</Button>
	</Form>);
};

export default Activate;