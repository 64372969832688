// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Arrow Component */

.cUgT_xSAa6d8KTZgzKY9 {
	--ui-arrow-size: 10px;
	--ui-arrow-color: #5EBED6;
	--ui-arrow-max: var(--ui-arrow-size) solid var(--ui-arrow-color);
	--ui-arrow-min: calc(var(--ui-arrow-size) / 2) solid transparent;
}

.cUgT_xSAa6d8KTZgzKY9::after {
	content: "";
	display: block;
	width: 0;
	height: 0;
	transition: rotate 0.1s;
}

.cUgT_xSAa6d8KTZgzKY9.zGazfdhx0TJZBOMuY9KW::after {
	border-left: var(--ui-arrow-min);
	border-right: var(--ui-arrow-min);
	border-top: var(--ui-arrow-max);
}

.cUgT_xSAa6d8KTZgzKY9.I2O45nuhWMpwqbhk7DJA::after {
	border-left: var(--ui-arrow-min);
	border-right: var(--ui-arrow-min);
	border-bottom: var(--ui-arrow-max);
}

.cUgT_xSAa6d8KTZgzKY9.BhSAZ_uKCVWFgVB7FRza::after {
	border-top: var(--ui-arrow-min);
	border-right: var(--ui-arrow-max);
	border-bottom: var(--ui-arrow-min);
}

.cUgT_xSAa6d8KTZgzKY9.k7oS7IgkcYNm1_XtwG_M::after {
	border-top: var(--ui-arrow-min);
	border-left: var(--ui-arrow-max);
	border-bottom: var(--ui-arrow-min);
}`, "",{"version":3,"sources":["webpack://./src/Components/Arrow/style.module.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;CACC,qBAAqB;CACrB,yBAAyB;CACzB,gEAAgE;CAChE,gEAAgE;AACjE;;AAEA;CACC,WAAW;CACX,cAAc;CACd,QAAQ;CACR,SAAS;CACT,uBAAuB;AACxB;;AAEA;CACC,gCAAgC;CAChC,iCAAiC;CACjC,+BAA+B;AAChC;;AAEA;CACC,gCAAgC;CAChC,iCAAiC;CACjC,kCAAkC;AACnC;;AAEA;CACC,+BAA+B;CAC/B,iCAAiC;CACjC,kCAAkC;AACnC;;AAEA;CACC,+BAA+B;CAC/B,gCAAgC;CAChC,kCAAkC;AACnC","sourcesContent":["/* Arrow Component */\r\n\r\n.container {\r\n\t--ui-arrow-size: 10px;\r\n\t--ui-arrow-color: #5EBED6;\r\n\t--ui-arrow-max: var(--ui-arrow-size) solid var(--ui-arrow-color);\r\n\t--ui-arrow-min: calc(var(--ui-arrow-size) / 2) solid transparent;\r\n}\r\n\r\n.container::after {\r\n\tcontent: \"\";\r\n\tdisplay: block;\r\n\twidth: 0;\r\n\theight: 0;\r\n\ttransition: rotate 0.1s;\r\n}\r\n\r\n.container.down::after {\r\n\tborder-left: var(--ui-arrow-min);\r\n\tborder-right: var(--ui-arrow-min);\r\n\tborder-top: var(--ui-arrow-max);\r\n}\r\n\r\n.container.up::after {\r\n\tborder-left: var(--ui-arrow-min);\r\n\tborder-right: var(--ui-arrow-min);\r\n\tborder-bottom: var(--ui-arrow-max);\r\n}\r\n\r\n.container.left::after {\r\n\tborder-top: var(--ui-arrow-min);\r\n\tborder-right: var(--ui-arrow-max);\r\n\tborder-bottom: var(--ui-arrow-min);\r\n}\r\n\r\n.container.right::after {\r\n\tborder-top: var(--ui-arrow-min);\r\n\tborder-left: var(--ui-arrow-max);\r\n\tborder-bottom: var(--ui-arrow-min);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cUgT_xSAa6d8KTZgzKY9`,
	"down": `zGazfdhx0TJZBOMuY9KW`,
	"up": `I2O45nuhWMpwqbhk7DJA`,
	"left": `BhSAZ_uKCVWFgVB7FRza`,
	"right": `k7oS7IgkcYNm1_XtwG_M`
};
export default ___CSS_LOADER_EXPORT___;
