import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { default as SelectUI } from "./Select";
import Option from "./Option";
import Optgroup from "./Optgroup";
;
const Select = {
    ...forwardRef(({ ...args }, ref) => _jsx(SelectUI, { ...args, ref: ref })),
    Option,
    Optgroup
};
export { Option as SelectOption, Optgroup as SelectOptgroup };
export default Select;
